<script setup lang="ts">
import { menuCategoryList } from "~/stores/index";
import { buildImgUrl } from '@/base/lib';
import { lanIndex } from '@/system/lang';
import { newHttpContext } from '~/base/http';
import { getProdCategoryList } from '@/ctrl/product-ctrl';
import { ModuleTyp } from "@/base/sys-config";

const ctx = newHttpContext(useNuxtApp())
const categoryCards = ref<HTMLElement>()
await getProdCategoryList(ctx)

onMounted(() => {
    window.addEventListener('resize', adjustCategoryCards)
    adjustCategoryCards()
})

function adjustCategoryCards() {
    if (categoryCards.value) {
        const w = categoryCards.value.offsetWidth
        let cardWidth = w
        if (w > 480) {
            const column = Math.ceil(w / 380)
            cardWidth = Math.floor((w - (16 * column - 16)) / column)
        }
        for (const cardElement of categoryCards.value.children) {
            (cardElement as HTMLDivElement).style.width = `${cardWidth}px`
        }
    }
}

function linkToMenu() {
    navigateTo('/products')
}
</script>

<template>
    <section ref="categoryCards" class="category-cards flex-wrap flex">
        <div v-for="(category, index) in menuCategoryList" class="overlap-layout" @click="linkToMenu">
            <NuxtImg :src="buildImgUrl(ModuleTyp.prodCategory, category.img)" class="img" width="128px"
                height="128px" />
            <div class="name">{{ category.name[lanIndex] }}</div>
        </div>
    </section>
</template>

<style>
.category-cards {
    row-gap: 1rem;
    column-gap: 1rem;
}

.category-cards>div {
    width: 16rem;
    height: 16rem;
    border-radius: .3rem;
    cursor: pointer;
}

.category-cards .img {
    width: 100%;
    height: 100%;
    display: block;
}

.category-cards .name {
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: black;
    padding-left: 1rem;
    color: white;
}
</style>