<script setup lang="ts">

</script>

<template>
<section class="introduction">
    <h3>欢迎来到[我们的平台]——您的二手LED屏和灯光音响设备专家！</h3>
    <p class="caption">专业、优质、可信赖</p>
    <p class="desc">在[我们平台]，我们专注于提供高质量的二手LED屏和灯光音响设备。无论您是举办大型活动、会议还是需要改善您的舞台效果，我们都能为您提供最合适的解决方案。</p>
    <p class="caption">为什么选择我们？</p>
    <p class="caption">精选产品，质量保证</p>
    <p class="desc">我们所有的二手设备都经过严格的质量检测和专业维护，确保每一件产品都能以最佳状态投入使用。无论是LED显示屏、舞台灯光还是音响设备，我们都为您精心挑选。</p>
    <p class="caption">价格优惠，性价比高</p>
    <p class="desc">相比全新设备，我们的二手设备在价格上更具竞争力，帮助您节省成本的同时，享受高品质的演出效果。我们致力于为客户提供最具性价比的解决方案。</p>
    <p class="caption">专业服务，全面支持</p>
    <p class="desc">我们的团队由经验丰富的专业人士组成，能够为您提供从选购到安装、调试的一站式服务。无论您遇到任何问题，我们都将在第一时间为您提供支持和解决方案。</p>
    <p class="caption">多样选择，满足需求</p>
    <p class="desc">我们库存丰富，涵盖各种型号和品牌的LED屏、灯光和音响设备，满足不同场景和需求。无论您是小型活动还是大型演出，我们都有适合的设备供您选择。</p>
    <p class="caption">客户案例</p>
    <p class="desc">我们已经为众多客户提供了优质的二手设备和服务，赢得了广泛的好评。请浏览我们的成功案例，了解更多客户对我们的评价和使用体验。</p>
    <p class="caption">联系我们</p>
    <p class="desc">如果您对我们的产品或服务有任何疑问，请随时联系我们。我们的专业团队将竭诚为您服务，帮助您找到最合适的二手LED屏和灯光音响设备。</p>
    <p class="desc">迎前来咨询，期待与您的合作！</p>
</section>
</template>

<style>
.introduction{
    background-color: black;
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.introduction h3{
    font-weight: bolder; font-size: 1.4em;
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.introduction .caption{
    font-weight: bolder; font-size: 1.2em;
    margin-top: 3rem;
    margin-bottom: 1rem;
}
.introduction .desc{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
</style>