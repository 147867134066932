<script setup lang="ts">
const props = defineProps<{
    imageUrlList: string[]
    interval: number
}>()

const slideShow = ref<HTMLDivElement>()
// const thumbnail = ref<HTMLDivElement>()
const dotList = ref<HTMLDivElement>()
let index = 0
let intervalId:NodeJS.Timeout

onMounted(() => {
    startAutoSideShow()
})

onUnmounted(()=>clearInterval(intervalId))

function startAutoSideShow(){
    showImage(index)

    intervalId = setInterval(function () {
        showNext()
    }, props.interval)
}

function showImage(idx: number) {
    if (slideShow.value) {
        for (let i = 0; i < slideShow.value.children.length; i++) {
            slideShow.value.children[i].classList.remove('active')
        }
        slideShow.value.children[idx].classList.add('active')
    }
    // if (thumbnail.value) {
    //     for (let i = 0; i < thumbnail.value.children.length; i++) {
    //         thumbnail.value.children[i].classList.remove('active')
    //     }
    //     thumbnail.value.children[idx].classList.add('active')
    // }
    if (dotList.value) {
        for (let i = 0; i < dotList.value.children.length; i++) {
            dotList.value.children[i].classList.remove('active')
        }
        dotList.value.children[idx].classList.add('active')
    }
}

function jumpToImage(idx:number){
    clearInterval(intervalId)
    index = idx
    startAutoSideShow()
}

function showNext() {
    if (slideShow.value) {
        const next = index++
        showImage(next)
        if (index >= slideShow.value.children.length) {
            index = 0
        }
    }
}

// function showPrev() {
//     if (slideShow.value) {
//         const prev = index--
//         showImage(prev)
//         if (index <= 0) {
//             index = slideShow.value.children.length - 1
//         }
//     }
// }
</script>

<template>
    <div class="slide-show">
        <div ref="slideShow" class="image-list">
            <div v-for="url in imageUrlList">
                <img :src="url" />
            </div>
        </div>
        <!-- <div class="thumbnail-list-wrap flex-center-hor">
            <div ref="thumbnail" class="thumbnail-list flex">
                <div v-for="(url, index) in imageUrlList" @click="jumpToImage(index)">
                    <img :src="url" />
                </div>
            </div>
        </div> -->
        <div class="dot-list-wrap flex-center-hor">
            <div ref="dotList" class="dot-list flex">
                <div v-for="(url, index) in imageUrlList"></div>
            </div>
        </div>
    </div>
</template>

<style>
.slide-show .image-list>div {
    display: none;
}

.slide-show .image-list>div.active {
    display: block;
    animation-name: fadeSlideShowImage;
    animation-duration: 2s;
}

.slide-show .image-list>div>img {
    width: 100%;
    display: block;
}

/* .slide-show .thumbnail-list-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.slide-show .thumbnail-list>div{
    cursor: pointer;
}

.slide-show .thumbnail-list>div.active {
    border: solid 1px white;
}

.slide-show .thumbnail-list>div:not(:first-child) {
    margin-left: 1rem;
}

.slide-show .thumbnail-list>div>img {
    width: 8rem;
    display: block;
} */
.slide-show .dot-list-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.slide-show .dot-list>div {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: white;
}

.slide-show .dot-list>div.active {
    border: solid 1px rgb(255, 0, 255);
}

.slide-show .dot-list>div:not(:first-child) {
    margin-left: 1rem;
}

@keyframes fadeSlideShowImage {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}
</style>