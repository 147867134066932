<script setup lang="ts">
import SlideShow from '~/components/SlideShow.vue';

useSeoMeta({
    title: 'Dancing Night, a second-hand lighting equipment trading platform',
    description: 'a second-hand lighting equipment trading platform, projection light, laser light, sound equipment',
})

const imageUrlList = [
    '/wallpapers-01.jpg',
    '/wallpapers-02.jpg',
    '/wallpapers-03.jpg',
    '/wallpapers-04.jpg',
    '/wallpapers-05.jpg',
    '/wallpapers-06.jpg'
]
</script>

<template>
    <div class="content-body">
        <PageHeader />
        <SearchBox />
    </div>
    <div class="h2rem"></div>
    <SlideShow :image-url-list="imageUrlList" :interval="4000" />
    <div class="content-body">
        <main class="index-main">
            <IndexIntroduction />
            <IndexCategoryCards />            
        </main>
        <PageFooter />
    </div>
</template>